import React from "react";
import ReactDOM from "react-dom";
import "react-app-polyfill/stable";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { userLoggedInByGoogle } from "redux/auth/action";
import App from "./App";
import moment from "moment-timezone";
import "moment/locale/id";
import ReactGA from "react-ga";
import env from "domain/Environment/Env";
import { ErrorReport, ErrorMessageReport } from "domain/Error/ErrorReport";
import PageError from "components/Error/PageError";
import ErrorBoundary from "components/Error/ErrorBoundary";
import numeral from "numeral";
import { worker } from "test/serverForBrowser";
import reportWebVitals from "./reportWebVitals";
import "react-datetime/css/react-datetime.css";

function prepare() {
  // Run API mocking for browser development.
  // Please note, mocking for testing it has different setup. Check on test folder.
  if (env.isLocal()) {
    return worker.start({
      onUnhandledRequest: "warn",
    });
  }
  return Promise.resolve();
}

prepare().then(() => {
  env.check();

  // Handle global error
  if (env.isDev() || env.isProd()) {
    window.onerror = function (message, url, lineNo, columnNo, error) {
      if (error instanceof Error) {
        ErrorReport(error);
      } else {
        ErrorMessageReport(`${url} - on ${lineNo},${columnNo}: ${message}`);
      }
      return false;
    };
    window.onunhandledrejection = function (e) {
      ErrorMessageReport(
        `Unhandled rejection (promise: ${e.promise}, reason: ${e.reason}).`
      );
    };
  }

  // Number formatting library
  numeral.register("locale", "id", {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    abbreviations: {
      thousand: "K",
      million: "JT",
      billion: "M",
      trillion: "T",
    },
    ordinal: function (number) {
      return "";
    },
    currency: {
      symbol: "Rp",
    },
  });
  numeral.locale("id");

  // set timezone and moment lang
  moment.locale("id");
  const localTimezone = moment.tz.guess();
  const indonesianTimezone = ["Asia/Jakarta", "Asia/Makassar", "Asia/Jayapura"];
  if (indonesianTimezone.includes(localTimezone)) {
    moment.tz.setDefault(localTimezone);
  } else {
    moment.tz.setDefault(process.env.REACT_APP_DEFAULT_TIMEZONE);
  }

  // relogin user
  const auth = localStorage.getItem("auth");
  if (auth) {
    store.dispatch(userLoggedInByGoogle(JSON.parse(auth)));
  }

  // Google Analytic
  if (env.isProd()) {
    ReactGA.initialize("UA-158686248-13");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  document.getElementById("app-loader").remove();

  ReactDOM.render(
    <ErrorBoundary fallback={<PageError />}>
      <BrowserRouter>
        <React.StrictMode>
          <Provider store={store}>
            <App />
          </Provider>
        </React.StrictMode>
      </BrowserRouter>
    </ErrorBoundary>,
    document.getElementById("root")
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
