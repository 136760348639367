import React from "react";
import Logo from "./Logo";
import QuickNav from "./QuickNav";
import ErrorBoundary from "components/Error/ErrorBoundary";

const Header = (props) => (
  <div className="nk-header nk-header-fixed is-light">
    <div className="container-lg wide-xl">
      <div className="nk-header-wrap">
        <ErrorBoundary fallback={<span></span>}>
          <Logo />

          <QuickNav />
        </ErrorBoundary>
      </div>
    </div>
  </div>
);

export default Header;
