import React from "react";
import { Switch, withRouter } from "react-router-dom";
import AnyRoute from "./components/Routes/AnyRoute";
import UserRoute from "./components/Routes/UserRoute";
import GuestRoute from "./components/Routes/GuestRoute";
import ReactGA from "react-ga";
import env from "domain/Environment/Env";
import lazyDelay from "domain/React/LazyDelay";
import PageFallback from "components/Loading/PageFallback";
import ErrorBoundary from "components/Error/ErrorBoundary";

import Subscription from "./components/Layouts/Subscription";
import Blank from "./components/Layouts/Blank";

import LoginPage from "pages/LoginPage";
import LogoutPage from "pages/LogoutPage";
import NoInternet from "components/Error/NoInternet";
const E_404 = lazyDelay(() => import("./pages/E_404"));

const AdministratorListPage = lazyDelay(() =>
  import("./pages/AdministratorListPage")
);
const AdministratorAddPage = lazyDelay(() =>
  import("./pages/AdministratorAddPage")
);
const AdministratorEditPage = lazyDelay(() =>
  import("./pages/AdministratorEditPage")
);

const AreaPage = lazyDelay(() => import("./pages/AreaPage"));

const InstitutionListPage = lazyDelay(() =>
  import("./pages/Institution/ListPage")
);
const InstitutionCreatePage = lazyDelay(() =>
  import("./pages/Institution/CreatePage")
);
const InstitutionEditPage = lazyDelay(() =>
  import("./pages/Institution/EditPage")
);

const VerificationFormulirListPage = lazyDelay(() =>
  import("./pages/PPDB/VerificationListPage")
);
const ApprovalFormulirListPage = lazyDelay(() =>
  import("./pages/PPDB/ApprovalListPage")
);
const DeletedFormulirListPage = lazyDelay(() =>
  import("./pages/PPDB/DeletedListPage")
);
const FormulirPage = lazyDelay(() =>
  import("./pages/PPDB/FormulirPage")
);

const ProfilePage = lazyDelay(() =>
  import("./pages/ProfilePage")
);
const LogListPage = lazyDelay(() =>
  import("./pages/LogListPage")
);

const App = ({ history }) => {
  history.listen((location) => {
    if (!env.isProd()) return;
    ReactGA.pageview(location.pathname + location.search);
  });

  return (
    <React.Suspense fallback={<PageFallback />}>
      <Switch>
        <UserRoute
          path="/"
          exact
          layout={Subscription}
          component={InstitutionListPage}
          title="Lembaga"
        />

        <UserRoute
          path="/administrator/add"
          exact
          layout={Subscription}
          component={AdministratorAddPage}
          title="Tambah Admin"
        />
        <UserRoute
          path="/administrator/:id"
          exact
          layout={Subscription}
          component={AdministratorEditPage}
          title="Ubah Admin"
        />
        <UserRoute
          path="/administrator"
          exact
          layout={Subscription}
          component={AdministratorListPage}
          title="Daftar Admin"
        />

        <UserRoute
          path="/area"
          exact
          layout={Subscription}
          component={AreaPage}
          title="Area"
        />

        <UserRoute
          path="/institution"
          exact
          layout={Subscription}
          component={InstitutionListPage}
          title="Lembaga"
        />
        <UserRoute
          path="/institution/create"
          exact
          layout={Subscription}
          component={InstitutionCreatePage}
          title="Buat Lembaga"
        />
        <UserRoute
          path="/institution/:id"
          exact
          layout={Subscription}
          component={InstitutionEditPage}
          title="Ubah Lembaga"
        />

        <UserRoute
          path="/formulir/verification"
          exact
          layout={Subscription}
          component={VerificationFormulirListPage}
          title="Daftar Verifikasi Formulir"
        />
        <UserRoute
          path="/formulir/approval"
          exact
          layout={Subscription}
          component={ApprovalFormulirListPage}
          title="Daftar Kelulusan Formulir"
        />
        <UserRoute
          path="/formulir/deleted"
          exact
          layout={Subscription}
          component={DeletedFormulirListPage}
          title="Daftar Formulir Telah Dihapus"
        />
        <UserRoute
          path="/formulir/detail/:id"
          exact
          layout={Subscription}
          component={FormulirPage}
          title="Detail Formulir"
        />

        <UserRoute
          path="/profile"
          exact
          layout={Subscription}
          component={ProfilePage}
          title="Akun Saya"
        />

        <UserRoute
          path="/log"
          exact
          layout={Subscription}
          component={LogListPage}
          title="Log"
        />

        <UserRoute
          path="/logout"
          exact
          layout={Blank}
          component={LogoutPage}
          title="Sedang keluar..."
        />
        <GuestRoute
          path="/login"
          exact
          component={LoginPage}
          title="Masuk ke Arjuna"
        />

        <AnyRoute component={E_404} title="Halaman Tidak Ditemukan" />
      </Switch>
    </React.Suspense>
  );
};

const AppWithRouter = withRouter(App);

export default (props) => (
  <ErrorBoundary fallback={<NoInternet />}>
    <AppWithRouter {...props} />
  </ErrorBoundary>
);
