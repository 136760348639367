import React from "react";
import PropTypes from "prop-types";
import GoogleLogin from "react-google-login";
import userAPI from "api/user";
import Alert from "components/atoms/Alert/Alert";
import { loginGoogle } from "redux/auth/action";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import queryParams from "query-string";
import { useReducer } from "react";
import { getGoogleErrorMessage } from "utils/misc";
import { ErrorReport } from "domain/Error/ErrorReport";
import Button from "components/atoms/Button/Button";
import SimpleModal from "components/molecules/Modal/SimpleModal";

const DEFAULT_ERROR_MESSAGE = "Terjadi kesalahan.";

const ACTION = {
  FAILED: "FAILED",
  RESET: "",
};
const INITIAL_STATE = {
  isFailed: false,
  message: null,
};
function reducer(state, action) {
  switch (action.type) {
    case ACTION.FAILED:
      return {
        ...state,
        isFailed: true,
        message: action.message || DEFAULT_ERROR_MESSAGE,
      };
    case ACTION.RESET:
      return { ...INITIAL_STATE };
    default:
      throw new Error("Unhandle action type: " + action.type);
  }
}

export default function GoogleButton(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [failState, dispatchFail] = useReducer(reducer, INITIAL_STATE);

  async function handleConnect(googleUser) {
    try {
      dispatchFail({ type: ACTION.RESET });

      // login
      const auth = await userAPI.loginGoogle({
        google_token: googleUser.getAuthResponse().id_token,
      });
      dispatch(loginGoogle(auth));

      // redirect
      const targetURL = decodeURIComponent(
        queryParams.parse(location.search).to || ""
      );
      if (targetURL) {
        history.push(targetURL);
      } else {
        history.push("/");
      }
    } catch (err) {
      ErrorReport(err);
      dispatchFail({ type: ACTION.FAILED });
    }
  }

  function handleConnectFailed(res) {
    if (!res.error) {
      throw res;
    }
    if (res.error === "popup_closed_by_user") {
      return;
    }
    dispatchFail({
      type: ACTION.FAILED,
      message: getGoogleErrorMessage(res, DEFAULT_ERROR_MESSAGE),
    });
  }

  return (
    <div>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        render={(renderProps) => (
          <Button
            variant="primary"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            size="lg"
            round
          >
            Masuk
          </Button>
        )}
        buttonText="Login"
        onSuccess={handleConnect}
        onFailure={handleConnectFailed}
        cookiePolicy={"single_host_origin"}
      />

      {failState.isFailed && (
        <ErrorModal
          message={failState.message}
          onClose={() => dispatchFail({ type: ACTION.RESET })}
        />
      )}
    </div>
  );
}

function ErrorModal({ message, onClose }) {
  return (
    <SimpleModal title="Galat Masuk Google" size="sm" onClose={onClose}>
      <Alert variant="warning">{message}</Alert>
    </SimpleModal>
  );
}

GoogleButton.propTypes = {};
