import React, { useEffect, useRef, useState } from "react";
import myNotificationAPI from "api/mynotification";
import moment from "moment-timezone";
import cx from "classnames";
import useFetchList from "hooks/useFetchList";
import Skeleton from "react-loading-skeleton";
import { range } from "utils/array";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router";
import ClickOutsideAlerter from "components/Event/ClickOutsideAlerter";
import { ErrorMessageReport, ErrorReport } from "domain/Error/ErrorReport";

const BellContainer = styled.div`
  &:after {
    background: #e85347;
    content: ${(props) => (props.hasUnread ? "''" : "none")};
  }
`;

export default function Notif() {
  const [isShow, setShow] = useState(false);
  const [hasUnread, setHasUnread] = useState(false);
  const location = useLocation();

  useEffect(() => {
    async function fetch() {
      const notifs = await myNotificationAPI.get({ limit: 10, offset: 0 });
      const totalUnread = notifs.filter(({ status }) => status === "unread")
        .length;
      setHasUnread(totalUnread > 0);
    }
    fetch();
  }, [location]);

  function toggleDropdown(e) {
    e && e.preventDefault();
    setShow((isShow) => !isShow);
  }

  return (
    <li className="dropdown notification-dropdown">
      <ClickOutsideAlerter when={isShow} onClickOutside={toggleDropdown}>
        <a
          href="#"
          className="dropdown-toggle nk-quick-nav-icon"
          data-toggle="dropdown"
          onMouseDown={toggleDropdown}
        >
          <BellContainer
            hasUnread={hasUnread}
            className="icon-status icon-status-info "
          >
            <em className="icon ni ni-bell"></em>
          </BellContainer>
        </a>
        {isShow && <NotifDropdown onClose={() => setShow(false)} />}
      </ClickOutsideAlerter>
    </li>
  );
}

const NotifDropdown = function NotifDropdown({ onClose }) {
  const { isFetched, data: notifs, isEmpty } = useFetchList({
    onFetch: () => myNotificationAPI.get({ limit: 10, offset: 0 }),
  });

  const ref = useRef();
  useEffect(() => {
    ref.current.focus(); // so we can hide dropdown after onBlur
  }, []);

  return (
    <div
      tabIndex="-1"
      ref={ref}
      className="dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s1 show"
      style={{ outline: "none" }}
    >
      <div className="dropdown-head">
        <span className="sub-title nk-dropdown-title">Notifikasi</span>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            myNotificationAPI.markAllAsRead().then(() => onClose());
          }}
        >
          Tandai Semua Telah Dibaca
        </a>
      </div>
      <div className="dropdown-body">
        <div className="nk-notification">
          {!isFetched &&
            range(3).map((index) => <SkeletonNotifItem key={index} />)}
          {isFetched && isEmpty && <NotifItemEmpty />}
          {isFetched &&
            !isEmpty &&
            notifs.map((notif) => (
              <NotifItem key={notif} notif={notif} onCloseDropdown={onClose} />
            ))}
        </div>
      </div>
      {/* <div className="dropdown-foot center">
            <a href="#">Lihat Semua</a>
          </div> */}
    </div>
  );
};

const NotifItem = ({ notif, onCloseDropdown }) => {
  const history = useHistory();
  const backgroundClassname = notif.status === "unread" ? "bg-primary-dim" : "";

  function handleClick() {
    // TODO: this code duplicated in other layout. Please make it DRY.
    try {
      switch (notif.event) {
        case "formulir_verification_status_changed": {
          history.push(`/admission/formulir/${notif.payload.formulir.id}`);
          break;
        }
        case "formulir_approval_status_changed": {
          history.push(`/admission/formulir/${notif.payload.formulir.id}`);
          break;
        }
        case "formulir_note_created": {
          history.push(
            `/admission/formulir/${notif.payload.formulir.id}?show_note=true`
          );
          break;
        }
        case "formulir_created": {
          history.push(`/admission/formulir/${notif.payload.formulir.id}`);
          break;
        }
        case "user_registered": {
          // do nothing
          break;
        }
        default: {
          throw new Error("Unhandled notification event = " + notif.event);
        }
      }
      myNotificationAPI.markAsRead(notif.id);
      onCloseDropdown();
    } catch (err) {
      ErrorReport(err);
    }
  }

  return (
    <div
      onClick={handleClick}
      className={cx(
        "nk-notification-item",
        "dropdown-inner",
        backgroundClassname
      )}
    >
      <div className="nk-notification-content" style={{ cursor: "pointer" }}>
        <div className="nk-notification-text">
          <strong>{notif.title}</strong>
        </div>
        <div className="nk-notification-text">{notif.message}</div>
        <div className="nk-notification-time">
          {moment(notif.created_at).format("D MMM YYYY HH:mm z")}
        </div>
      </div>
    </div>
  );
};

const NotifItemEmpty = () => {
  return (
    <div className="nk-notification-item dropdown-inner">
      <div className="nk-notification-content text-soft">
        Tidak ada notifikasi.
      </div>
    </div>
  );
};

function SkeletonNotifItem() {
  return (
    <div className={cx("nk-notification-item", "dropdown-inner")}>
      <div className="nk-notification-content">
        <div>
          <Skeleton width={100} />
        </div>
        <div>
          <Skeleton width={100} />
        </div>
      </div>
    </div>
  );
}
