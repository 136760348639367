import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "./variables";

const LOG = {
  id: 1,
  message:
    "************ CAUSE OF ERROR ************\n\njava.lang.NullPointerException: Attempt to invoke virtual method 'java.lang.Boolean com.edulogy.edulogy.models.assignment.AssignmentData.getStatusSubmit()' on a null object reference\n\tat com.edulogy.edulogy.fragments.assignment.CommonFragment$2.onClick(CommonFragment.java:134)\n\tat android.view.View.performClick(View.java:7870)\n\tat android.widget.TextView.performClick(TextView.java:14970)\n\tat android.view.View.performClickInternal(View.java:7839)\n\tat android.view.View.access$3600(View.java:886)\n\tat android.view.View$PerformClick.run(View.java:29363)\n\tat android.os.Handler.handleCallback(Handler.java:883)\n\tat android.os.Handler.dispatchMessage(Handler.java:100)\n\tat android.os.Looper.loop(Looper.java:237)\n\tat android.app.ActivityThread.main(ActivityThread.java:7814)\n\tat java.lang.reflect.Method.invoke(Native Method)\n\tat com.android.internal.os.RuntimeInit$MethodAndArgsCaller.run(RuntimeInit.java:493)\n\tat com.android.internal.os.ZygoteInit.main(ZygoteInit.java:1075)\n\n************ DEVICE INFORMATION ***********\nBrand: samsung\nDevice: m11q\nModel: SM-M115F\nId: QP1A.190711.020\nProduct: m11qnnxx\n************ FIRMWARE ************SDK: 29\nRelease: 10\nIncremental: M115FXXU1ATE3\n",
  created_at: "2021-02-14T02:31:00.000000Z",
  meta: {
    source: "android",
  },
};

export default [
  rest.get(`${BASE_URL}/v1/log`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: [LOG],
      })
    );
  }),
];
