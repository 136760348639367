import React from "react";
import HeadingMenu from "./HeadingMenu";
import ItemMenu from "./ItemMenu";
import DropdownMenu from "./DropdownMenu";

export default (props) => (
  <ul className="nk-menu">
    <HeadingMenu text="Menu" />
    <ItemMenu icon="building" link="/institution" text="Lembaga" />
    <ItemMenu icon="map" link="/formulir/approval" text="PPDB" />
    <ItemMenu icon="user-list" link="/administrator" text="Administrator" />
    <ItemMenu icon="location" link="/area" text="Area" />
    <ItemMenu icon="inbox" link="/log" text="Log" />
  </ul>
);
