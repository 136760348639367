import { useEffect, useMemo, useReducer, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const STATUS = {
  FETCHING: "FETCHING",
  FETCHED: "FETCHED",
};
const TYPE = STATUS;

const INITIAL_STATE = {
  status: STATUS.FETCHING,
  isEmpty: null,
  items: null,
};

export default function useFetchList({ onFetch, args = null }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [fetchId, setFetchId] = useState(uuidv4());

  useEffect(() => {
    dispatch({ type: TYPE.FETCHING });
    (async function () {
      const data = await onFetch(args);
      dispatch({ type: TYPE.FETCHED, data });
    })();
  }, [args, fetchId]); // eslint complain about onFetch is not listed as deps but we can safely ignore it cause our current requirement say onFetch is will always same.

  return useMemo(
    () => ({
      isFetching: state.status === STATUS.FETCHING,
      isFetched: state.status === STATUS.FETCHED,
      isEmpty: state.isEmpty,
      data: state.items,
      totalPages: state.totalPages,
      refetch: () => setFetchId(uuidv4()),
    }),
    [state]
  );
}

function reducer(state, action) {
  switch (action.type) {
    case TYPE.FETCHING: {
      return { ...INITIAL_STATE, status: STATUS.FETCHING };
    }
    case TYPE.FETCHED: {
      const hasPagination = action.data.total_pages !== undefined;
      if (hasPagination) {
        return {
          ...state,
          status: STATUS.FETCHED,
          isEmpty: action.data.items.length === 0,
          items: action.data.items,
          totalPages: action.data.total_pages,
        };
      }
      return {
        ...state,
        status: STATUS.FETCHED,
        isEmpty: action.data.length === 0,
        items: action.data,
        totalPages: 1,
      };
    }
    default: {
      throw new Error("Invalid action type: " + action.type);
    }
  }
}
