import { combineReducers } from 'redux';
import auth from './auth/reducer';
import page from './page/reducer';
import session from './session/reducer';

const appReducer = combineReducers({
    auth,
    page,
    session,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
