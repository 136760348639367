import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logout } from "redux/auth/action";

const Logout = ({ logout, accessToken, history }) => {
  useEffect(() => {
    async function run() {
      logout(accessToken).then(() => {
        history.push("/");
      });
    }

    const timeout = setTimeout(() => run(), 1000);
    return () => clearTimeout(timeout);
  }, []);

  return <p className="p-3 ff-mono text-dark">Sedang memproses logout...</p>;
};

Logout.propTypes = {};

const mapStateToProps = (state) => ({
  accessToken: state.auth.access_token,
});

export default connect(mapStateToProps, { logout })(Logout);
